<template>
	<div></div>
</template>

<script>
export default {
	created() {
		this.$toast.loading({
			duration: 0,
			message: '',
			forbidClick: true
		});
		this.mpLogin().then(async res => {
			let patData = await this.$service.post('order/payTemp');
			this.payH5Request(patData.data)
				.then(() => {
					this.$toast.clear();
					this.$toast.success({
						message: '支付成功',
						duration: 800
					});
				})
				.catch(() => {
					this.$toast.fail({
						message: '未支付',
						duration: 800
					});
				});
		});
	}
};
</script>

<style></style>
